import React, { useContext } from 'react';
import Landing from './Landing';
import MainPage from './MainPage';
import authContext from '../context/auth/authContext';
import MainLayout from '../layouts/_MainLayout';


const HomePage = () => {
  const { isLoggedIn } = useContext(authContext);

  if (!isLoggedIn) return <MainLayout><Landing /></MainLayout>;
  else return <MainLayout><MainPage /></MainLayout>;
};

export default HomePage;
