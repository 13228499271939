import moment from "moment";


export const formatDate = (unixTime) =>{
  try{
    let dateString = moment(unixTime).format('lll');
    return dateString;
  }
  catch(err){
    console.log(err.message);
  }
  
}


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const formatPrice = (num, decimals = 8) => {
  if (num >= 1) {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(num);
  }
  else {
    return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimals }).format(parseFloat(num).toFixed(4));
  }
}


export const SUPPORTED_CURRENCY = {
  1 : [
    {
      id : 0,
      name : "ETH",
      address : '',
      decimals : 18
    },
    {
      id : 1,
      name : "USDC",
      address : "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      decimals : 6
    },
    {
      id : 2,
      name : "BTC",
      address : "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      decimals : 8
    },
    {
      id : 3,
      name : "BNB",
      address : "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      decimals : 18
    },
    {
      id : 4,
      name : "BST",
      address : "0x4216f7B5833ED81CEeBCDF39f5ACD1e7F6F9B734",
      decimals : 18
    }
  ],
  56 : [
    {
      id : 0,
      name : "BNB",
      address : '',
      decimals : 18
    },
     {
      id : 1,
      name : "USDC",
      address : "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals : 18
    },
    {
      id : 2,
      name : "BTC",
      address : "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      decimals : 18
    },
    {
      id : 3,
      name : "ETH",
      address : "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals : 18
    }
  ]
  // 80001 : [
  //   {
  //     id : 0,
  //     name : "BNB",
  //     address : '',
  //     decimals : 18
  //   },
  //   {
  //     id : 1,
  //     name : "BST",
  //     address : '0xad5cb06f30b57218d0612fa99e128a19fdd022de',
  //     decimals : 18
  //   }
  // ]
}

export const SUPPORTED_CHAIN = [56,1];
export const DEFAULT_CURRENCY = 1;
export const ADMIN_WALLET = '0xD25D09F088A4B145be27D2B0252C30Ff49F49D7d';
export const WITHDRAW_RATE = 0.01;

export const RPC = {
  56 : 'https://bsc-dataseed1.defibit.io',
  1 : 'https://ethereum.publicnode.com',
  80001 : 'https://rpc.ankr.com/polygon_mumbai'
}