import React, { useContext, useEffect, useRef, useState } from 'react'
import './chatbox.css';
import gameContext from '../../context/game/gameContext';
import audioTune from '../../assets/game/message.mp3';


export default function ChatBox() {
    const { sentMessage, chatMessages, updateMessages , newMessages , setChatMessages , messageSound , setMessageSound } = useContext(gameContext);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const chatBodyRef = useRef(null);
    const audio = new Audio(audioTune);

    const handleSubmit = () => {
        sentMessage(message)
        setMessage('');

    }

    useEffect(() => {
        scrollToBottom()

        let interval = setInterval(() => {
            if (chatMessages) {
                updateMessages()
            }
        }, 2000);

        return () => clearInterval(interval);

    }, [chatMessages]);


    useEffect(()=>{
      
        if(Object.keys(newMessages).length > Object.keys(chatMessages).length && !messageSound){
            audio.play();
        }
        setChatMessages(newMessages);
    },[newMessages]);

    function scrollToBottom() {

        // Scroll to the bottom when the component mounts
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;

    }

    return (
        <div class="chat_box" style={{ height: isOpen ? 'auto' : 0 }} ref={chatBodyRef}>
            <div class="head">
                <div class="user">
                    <div class="name">Chat Box</div>
                </div>
                <div className='pointer mx-3' onClick={() => setMessageSound(!messageSound)}>
                    { messageSound ? <i class="fas fa-solid fa-volume-up"></i> : <i class="fas fa-solid fa-volume-off"></i>}
                </div>
                <div className='pointer mx-1' onClick={() => setIsOpen(!isOpen)}>
                    <i class="fas fa-solid fa-minus"></i>
                </div>
                
            </div>
            {isOpen &&
                <>
                    <div className='chat-body' >
                        {chatMessages && chatMessages.length > 0 &&
                            chatMessages.map((items, key) => {
                                return (

                                    <div class="body" key={key}>
                                        <div class="incoming">
                                            <div class="bubble">
                                                <p className='text-success'>{items.from ? items.from : ''}</p>
                                                <p>{items.message ? items.message : ''}</p>
                                            </div>
                                        </div>
                                        {/* <div class="outgoing">
                                        <div class="bubble lower">
                                            <p>Nah, it's cool.</p>
                                        </div>
                                    </div>
                                    <div class="incoming">
                                        <div class="bubble">
                                            <p>Hey, Father's Day is coming up..</p>
                                        </div>
                                    </div> */}
                                    </div>

                                )
                            })
                        }
                    </div>

                    <div class="foot">
                        <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} class="msg" placeholder="Type a message..." />
                        <button type="button" className='send-button' onClick={() => handleSubmit()}>
                            <i class="fas fa-paper-plane"></i>
                        </button>
                    </div>
                </>
            }
        </div>
    )
}
