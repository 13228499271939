import Axios from "axios";
import { useEffect, useState } from "react";

export const usePricesStats = (updater) => {
    const [stats, setStats] = useState({
        btc: 0,
        eth: 0,
        usdc: 0,
        bnb: 0,
        bst: 0,
    });


    useEffect(() => {
        const fetch = async () => {
            try {
                let response = await Axios.get('https://api.coingecko.com/api/v3/simple/price', {
                    params: {
                        ids: 'bitcoin,binancecoin,usd-coin,ethereum',
                        vs_currencies: 'usd',
                    },
                });

                const btcPrice = response.data.bitcoin.usd;
                const bnbPrice = response.data.binancecoin.usd;
                const usdcPrice = response.data['usd-coin'].usd;
                const ethPrice = response.data.ethereum.usd;
                let bstPrice = 0;
                response = await Axios.get('https://blockstarexchange.com/api/bst-price');

                if (response && response.data && response.data.type === 'success') {
                    bstPrice = response.data.message
                }

                setStats({
                    btc: btcPrice,
                    bnb: bnbPrice,
                    usdc: usdcPrice,
                    eth: ethPrice,
                    bst: bstPrice
                })
            }
            catch (err) {
                console.log(err.message);
            }
        }

        fetch()
    }, [updater])

    return stats;
}