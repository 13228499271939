import React from 'react';
import GlobalState from './global/GlobalState';
import AuthProvider from './auth/AuthProvider';
import LocaProvider from './localization/LocaProvider';
import ModalProvider from './modal/ModalProvider';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import Normalize from '../styles/Normalize';
import GlobalStyles from '../styles/Global';
import { BrowserRouter } from 'react-router-dom';
import WebSocketProvider from './websocket/WebsocketProvider';
import GameState from './game/GameState';

const Providers = ({ children }) => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GlobalState>
        <LocaProvider>

          <AuthProvider>
            <ModalProvider>

              <WebSocketProvider>
                <GameState>
                  <Normalize />
                  <GlobalStyles />
                  {children}
                </GameState>
              </WebSocketProvider>

            </ModalProvider>
          </AuthProvider>

        </LocaProvider>
      </GlobalState>
    </ThemeProvider>
  </BrowserRouter>
);

export default Providers;
