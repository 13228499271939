import React, { useContext, useEffect, useRef, useState } from 'react'
import micOn from '../assets/game/mic.png';
import micOff from '../assets/game/micnon.png';
import '../styles/soundbutton.css';
import gameContext from '../context/game/gameContext';
import { toast } from 'react-toastify';


export default function VoiceButton() {
    const { setUnMute, setMute , isMicOn } = useContext(gameContext);
   
    const handleMic = async () => {
        try {
            if (!isMicOn) {
                setUnMute();
            }
            else {
                setMute()
            }
        }
        catch (err) {
            toast.error(err.message === 'Permission denied' ? 'Please give microphone permission in your device' : err.message);
            console.log(err);
        }
    }

    return (
        <>
            <span className='voice-button' onClick={() => handleMic()}>
                {isMicOn ? (
                    <img src={micOn} className='voice-icon' alt="soundIcon" />
                ) : (
                    <img src={micOff} className='voice-icon' alt="soundIcon" />
                )}

            </span>
        </>
    )
}
