import React, { useContext } from 'react'
import soundIcon from '../assets/game/volume.png';
import muteIcon from '../assets/game/mute.png';
import '../styles/soundbutton.css';
import gameContext from '../context/game/gameContext';

export default function SoundButton() {
    const { isMute , setIsMute} = useContext(gameContext);
    return (
        <span className='sound-button' onClick={()=> setIsMute(!isMute)}>
            {isMute ? (
                <img src={muteIcon} className='sound-icon' alt="soundIcon" />
            ) : (
                <img src={soundIcon} className='sound-icon' alt="soundIcon" />
            )}  
            
        </span>
    )
}
