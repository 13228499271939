import React, { useContext, useState } from 'react';
import LogoWithText from '../../assets/img/logo-text.png';
import Logo from '../logo/LogoIcon';
import Container from '../layout/Container';
import styled from 'styled-components';
import { Link, withRouter } from 'react-router-dom';
import Hider from '../layout/Hider';
import Button from '../buttons/Button';
import ChipsAmount from '../user/ChipsAmount';
import HamburgerButton from '../buttons/HamburgerButton';
import Spacer from '../layout/Spacer';
import Text from '../typography/Text';
import { Modal } from 'react-bootstrap';
import WithdrawModal from '../modals/WithdrawModal';



const StyledNav = styled.nav`
  padding: 1rem 0;
  position: absolute;
  z-index: 99;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightestBg};
`;

const Navbar = ({
  loggedIn,
  chipsAmount,
  location,
  openModal,
  openNavMenu,
  className,
}) => {
  const [show, setShow] = useState(false);

  if (!loggedIn)
    return (
      <StyledNav className={className}>
        <Container contentCenteredMobile>
          <Link to="/">
            <img src={LogoWithText} alt="logo" />
          </Link>

          <Hider hideOnMobile>
            <Spacer>
              {location.pathname !== '/register' && (
                <Button as={Link} to="/register" primary small>
                  Register
                </Button>
              )}
              {location.pathname !== '/login' && (
                <Button as={Link} to="/login" primary small>
                  Login
                </Button>
              )}
            </Spacer>
          </Hider>
        </Container>
      </StyledNav>
    );
  else
    return (
      <StyledNav className={className}>
        <Container>
          <Link to="/">
            <Hider hideOnMobile>
              <img src={LogoWithText} alt="logo" />
            </Hider>
            <Hider hideOnDesktop>
              <Logo />
            </Hider>
          </Link>
          <Spacer >
            <ChipsAmount
              chipsAmount={chipsAmount}
              clickHandler={() => setShow(true)}
            />
            <Hider hideOnMobile>
              <Link to="/pricing" className="text-white bg-blue-700 rounded-lg text-sm px-3 py-3" primary small>
                Buy Chips
              </Link>
            </Hider>
            <HamburgerButton clickHandler={openNavMenu} />
          </Spacer>
          <WithdrawModal show={show} setShow={setShow} />
        </Container>
      </StyledNav>
    );
};

export default withRouter(Navbar);
