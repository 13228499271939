import React from 'react';
import PropTypes from 'prop-types';
import chipsIcon from '../../assets/icons/chips-img.png'

const PokerChip = ({ width, height }) => (
  <img src={chipsIcon} width={width} height={height} alt="pockerchips" />
);

PokerChip.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

PokerChip.defaultProps = {
  width: '60',
  height: '60',
};

export default PokerChip;
