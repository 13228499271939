import React, { useRef, useContext } from 'react';
import Container from '../components/layout/Container';
import { Redirect, Link } from 'react-router-dom';
import HeadingWithLogo from '../components/typography/HeadingWithLogo';
import Button from '../components/buttons/Button';
import { Input } from '../components/forms/Input';
import { Form } from '../components/forms/Form';
import { FormGroup } from '../components/forms/FormGroup';
import { ButtonGroup } from '../components/forms/ButtonGroup';
import { Label } from '../components/forms/Label';
import RelativeWrapper from '../components/layout/RelativeWrapper';
import ShowPasswordButton from '../components/buttons/ShowPasswordButton';
import useScrollToTopOnPageLoad from '../hooks/useScrollToTopOnPageLoad';
import authContext from '../context/auth/authContext';
import { TiledBackgroundImage } from '../components/decoration/TiledBackgroundImage';
import MainLayout from '../layouts/_MainLayout';


const RegistrationPage = () => {
  const { register, isLoggedIn } = useContext(authContext);

  useScrollToTopOnPageLoad();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const nicknameRef = useRef(null);

  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <MainLayout>
      <RelativeWrapper>
        <TiledBackgroundImage />
        <Container
          fullHeight
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="6rem 2rem 2rem 2rem"
          contentCenteredMobile
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              const name = nicknameRef.current.value;
              const email = emailRef.current.value;
              const password = passwordRef.current.value;

              if (
                name &&
                email &&
                password &&
                name.length >= 5 &&
                name.length <= 12 &&
                email.length >= 0 &&
                password.length >= 6
              ) {
                register(name, email, password);
              }
            }}
          >
            <HeadingWithLogo textCentered hideIconOnMobile={false}>
              Registration
            </HeadingWithLogo>


            <FormGroup>
              <div class="d-grid text-center">
                <div class="dt_social_login dt_social_login_btn">
                  {/* c7892f6fefb93438dd57149ed133805c5712c2f */}
                  <a href="https://blockstar.social/oauth?app_id=66fb59d01bcb7f334283" class="btn text-white text-center btn-lg fw-bold mb-2 btn-main">
                    <img src="https://alchemy.mypinata.cloud/ipfs/QmaTpWAbw33A1WPhPmN4wHA9DDpjMMn2w6WVYdaBFumVNe" class="w-10" height="35px" width="35px" />

                    <span class="w-5/6">Register with BlockStar</span>
                  </a>
                </div>
              </div>
              <div class="seperator"><b>OR</b></div>
              <Label htmlFor="email">
                E-mail
              </Label>
              <Input type="email" name="email" ref={emailRef} required />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="nickname">
                username
              </Label>
              <Input
                type="text"
                name="nickname"
                autoComplete="off"
                ref={nicknameRef}
                minLength="5"
                maxLength="12"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">
                Password
              </Label>
              <ShowPasswordButton passwordRef={passwordRef} />
              <Input
                type="password"
                name="password"
                minLength="6"
                autoComplete="new-password"
                ref={passwordRef}
                required
              />
            </FormGroup>
            {/* <FormGroup>
            <Label htmlFor="dob">Date of birth (18+)</Label>
            <Input type="date" name="dob" />
          </FormGroup> */}
            <ButtonGroup>
              <Button primary type="submit" fullWidth>
                Complete Registration
              </Button>
              <Link to="/login">
                I already have an account!
              </Link>
            </ButtonGroup>
          </Form>
        </Container>
      </RelativeWrapper>
    </MainLayout>
  );
};

export default RegistrationPage;
