import React, { useRef, useContext, useEffect } from 'react';
import Container from '../components/layout/Container';
import { Redirect, Link } from 'react-router-dom';
import HeadingWithLogo from '../components/typography/HeadingWithLogo';
import Button from '../components/buttons/Button';
import { Input } from '../components/forms/Input';
import { Form } from '../components/forms/Form';
import { FormGroup } from '../components/forms/FormGroup';
import { ButtonGroup } from '../components/forms/ButtonGroup';
import { Label } from '../components/forms/Label';
import RelativeWrapper from '../components/layout/RelativeWrapper';
import ShowPasswordButton from '../components/buttons/ShowPasswordButton';
import useScrollToTopOnPageLoad from '../hooks/useScrollToTopOnPageLoad';
import authContext from '../context/auth/authContext';
import { TiledBackgroundImage } from '../components/decoration/TiledBackgroundImage';
import MainLayout from '../layouts/_MainLayout';
import '../styles/tables.css';
import { useLocation, useHistory } from 'react-router-dom';
import Axios from 'axios';
import { api } from '../helpers/cookies';
import { toast } from 'react-toastify';


const LoginPage = () => {

  const { login, isLoggedIn, blockstarLogin } = useContext(authContext);

  useScrollToTopOnPageLoad();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory()

  const code = queryParams.get('code');
  const count = useRef(0);

  useEffect(() => {
    async function fetch() {
      if (count.current === 0 && code) {
        count.current = count.current + 1;
        let auth = await Axios
          .post(
            `https://blockstar.social/authorize?app_id=${process.env.REACT_APP_BLOCKSTAR_APPID}&app_secret=${process.env.REACT_APP_BLOCKSTAR_SECRET}&code=${code}`
          )
          .then((res) => {
            // console.log(res);
            return res.data
          })
          .catch((err) => {
            return false;
          })

        if (auth && auth.status === 200 && auth.access_token) {
          let userdata = await Axios
            .post(
              `https://blockstar.social/api_request?access_token=${auth.access_token}&type=get_user_data`
            )
            .then((res) => {
              // console.log(res);
              return res.data
            })
            .catch((err) => {
              return false;
            })

          if (userdata && userdata.status === 200 && userdata.user_data) {
            api.post('/api/users/loginwithblockstar', {
              email: userdata.user_data.email,
              name: userdata.user_data.username
            }).then((res) => {
              const token = res.data.token;
              blockstarLogin(token)
            });
          }
          else {
            toast.error('access token invalid');
            history.push('/');
          }
        }
        else {
          toast.error('access token invalid');
          history.push('/');
        }
      }
    }

    fetch();
  }, []);


  if (isLoggedIn) return <Redirect to="/" />;
  return (
    <MainLayout>
      <RelativeWrapper>
        <TiledBackgroundImage />
        <Container
          fullHeight
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="6rem 2rem 2rem 2rem"
          contentCenteredMobile
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              const email = emailRef.current.value;
              const password = passwordRef.current.value;

              email &&
                password &&
                email.length > 0 &&
                password.length > 0 &&
                login(email, password);
            }}
          >
            <HeadingWithLogo textCentered hideIconOnMobile={false}>
              Login
            </HeadingWithLogo>
            <FormGroup>
              <Label htmlFor="email">
                E-mail
              </Label>
              <Input
                type="email"
                name="email"
                ref={emailRef}
                required
                autoComplete="email"
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="password">
                Password
              </Label>
              <ShowPasswordButton passwordRef={passwordRef} />
              <Input
                type="password"
                name="password"
                ref={passwordRef}
                autoComplete="current-password"
                required
              />
            </FormGroup>
            <ButtonGroup>
              <Button primary type="submit" fullWidth>
                Login
              </Button>
              <div class="seperator"><b>OR</b></div>
              <div class="d-grid text-center">
                <div class="dt_social_login dt_social_login_btn">
                  {/* c7892f6fefb93438dd57149ed133805c5712c2f */}
                  <a href="https://blockstar.social/oauth?app_id=66fb59d01bcb7f334283" class="btn text-white text-center btn-lg fw-bold mb-2 btn-main">
                    <img src="https://alchemy.mypinata.cloud/ipfs/QmaTpWAbw33A1WPhPmN4wHA9DDpjMMn2w6WVYdaBFumVNe" class="w-10" height="35px" width="35px" />

                    <span class="w-5/6">Login with BlockStar</span>
                  </a>
                </div>
              </div>

              {/* <Link to="/">I foI do not have an account yet!rgot my password!</Link> */}
              <Link to="/register">
                I do not have an account yet!
              </Link>
            </ButtonGroup>
          </Form>
        </Container>
      </RelativeWrapper>
    </MainLayout>
  );
};

export default LoginPage;
