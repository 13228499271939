import React, { useEffect, useState } from 'react';
import MainLayout from '../layouts/_MainLayout';
import RelativeWrapper from '../components/layout/RelativeWrapper';
import '../styles/tables.css';
import { Modal } from 'react-bootstrap';
import { useAccount, useNetwork } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { ADMIN_WALLET, DEFAULT_CURRENCY, RPC, SUPPORTED_CHAIN, SUPPORTED_CURRENCY, formatPrice, trimAddress } from '../helpers/constant';
import allCrypto from '../assets/img/all_cryptos.png';
import paypalImg from '../assets/img/paypal.png'
import { api } from '../helpers/cookies';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { usePricesStats } from '../hooks/useState';
import { PayPalButtons } from "@paypal/react-paypal-js";
import tokenAbi from '../json/token.json';
import { ethers } from 'ethers';
import { useEthersSigner } from '../hooks/useEthersProvider';
import Web3 from 'web3';



export default function Pricing() {
    const [cryptoModal, setCryptoModal] = useState(false);
    const [fiatModal, setFiatModal] = useState(false);
    const { address, isConnected } = useAccount()
    const { chain } = useNetwork();
    const { open } = useWeb3Modal();
    const [plans, setPlans] = useState([]);
    const history = useHistory();
    const [rate, setRate] = useState('');
    const [cost, setCost] = useState(0);
    const [chips, setChips] = useState(0);
    const [payble, setPaybel] = useState(0);
    const [currency, setCurrency] = useState({
        id: 0,
        name: "",
        address: ""
    });
    const [currencyArray, setCurrencyArray] = useState([]);
    const stats = usePricesStats(1);
    const signer = useEthersSigner();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        api.post('/api/auth/getplans', {}, {
            headers: {
                'x-auth-token': localStorage.token,
            },
        }).then((res) => {

            if (res && res.data && res.data.error === 'OK') {
                setPlans(res.data.data)
            }
            else {
                toast.error(res.data.error);
                history.push('/');

            }
        });
    }, []);


    const handleCurrencyChange = async (value, name) => {
        setRate(stats[name.toString().toLowerCase()]);
        let currencyInfo = SUPPORTED_CURRENCY[chain && chain.id && SUPPORTED_CHAIN.includes(chain.id) ? chain.id : DEFAULT_CURRENCY][value];
        setCurrency(currencyInfo);
    }

    useEffect(() => {
        setPaybel(parseFloat(parseFloat(cost) / parseFloat(rate)).toFixed(6))
    }, [cost, rate])

    useEffect(() => {
        setCurrencyArray(chain && chain.id && (chain.id === 56 || chain.id === 1) ? SUPPORTED_CURRENCY[chain.id] : SUPPORTED_CURRENCY[DEFAULT_CURRENCY]);
    }, [chain]);


    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: cost.toString(), // specify the amount
                    },
                },
            ],
        });
    };

    const onApprove = (data, actions) => {
        console.log(data)
        return actions.order.capture().then((details) => {
            if (details && details.id && details.status === 'COMPLETED') {
                api.post('/api/auth/paypalbuy', {
                    tx_id: details.id,
                    chips,
                    cost,
                    status: 1,
                    remarks: JSON.stringify(details)
                }, {
                    headers: {
                        'x-auth-token': localStorage.token,
                    },
                }).then((res) => {

                    if (res && res.data && res.data.error === 'OK') {
                        toast.success('your last transaction is successfull.');
                        setFiatModal(false);
                    }
                    else {
                        toast.error(res.data.error);
                    }
                });
            }
            else {
                toast.error('payment details not found. if any money deduct contact customer support');
            }

        });
    };

    const handleBuy = async () => {
        if (address) {
            if (chain && chain.id && SUPPORTED_CHAIN.includes(chain.id)) {
                if (currency) {
                    try {
                        setLoading(true);
                        let tx;
                        if (currency.address) {
                            let tokenContract = new ethers.Contract(currency.address, tokenAbi, signer);
                            tx = await tokenContract.transfer(ADMIN_WALLET , ethers.utils.parseUnits(payble.toString() , currency.decimals));
                        }
                        else {
                            tx = await signer.sendTransaction({
                                to: ADMIN_WALLET,
                                value: ethers.utils.parseEther(payble.toString())
                            });
                        }

                        if (tx.hash) {
                            api.post('/api/auth/cryptobuy', {
                                tx_id: tx.hash,
                                chips,
                                cost,
                                chain_id: chain.id,
                                status: 2,
                                remarks: JSON.stringify(tx)
                            }, {
                                headers: {
                                    'x-auth-token': localStorage.token,
                                },
                            }).then((res) => {
                                if (res && res.data && res.data.error === 'OK') {

                                }
                                else {
                                    toast.error(res.data.error);
                                }
                            });
                        }

                        toast.loading('Waiting for confirmation.');
                        let web3 = new Web3(RPC[chain.id]);
                        var interval = setInterval(async function () {
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss()
                                    toast.success('success ! your last transaction is success');
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.dismiss()
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.dismiss()
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    catch (err) {
                        console.log(err.message);
                        toast.error(err.reason ? err.reason : err.message);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('please select valid currency');
                }
            }
            else {
                toast.error('selected network not supported');
            }

        }
        else {
            toast.error('please connect wallet');
        }
    }

    return (
        <MainLayout>
            <RelativeWrapper>
                <section class="bg-dark mt-5">
                    <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div class="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-white ">Pricing</h2>
                        </div>
                        <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                            {plans && plans.length > 0 && plans.map((items, key) => {
                                return (
                                    <div key={key} class="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border shadow border-gray-600 xl:p-8 bg-gray-800 text-white">
                                        <div class="flex justify-center items-baseline mt-3 mb-1">
                                            <span class="mr-2 text-5xl font-extrabold">${items.cost}</span>
                                            <span className='text-2xl'>usd</span>
                                        </div>
                                        <div class="align-items-center flex justify-center mt-2 mb-4">
                                            <svg class="w-5 h-5 mx-2 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                            <span>{formatPrice(items.chips)} Chips</span>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            <button type="button" onClick={() => {
                                                setFiatModal(true)
                                                setCost(items.cost)
                                                setChips(items.chips)
                                            }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                <img src={paypalImg} className='mx-auto' alt='allCrypto' style={{ height: "25px", width: "100px" }} />
                                                Paypal
                                            </button>
                                            <button type="button" onClick={() => {
                                                setCryptoModal(true)
                                                setCost(items.cost)
                                                setChips(items.chips)
                                            }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                <img src={allCrypto} className='mx-auto' alt='allCrypto' style={{ height: "25px", width: "100px" }} />
                                                Crypto
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </section>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={cryptoModal}
                    onHide={() => setCryptoModal(false)}
                >
                    <Modal.Header>
                        <Modal.Title>
                            Buy With Crypto
                        </Modal.Title>
                        <button type="button" onClick={() => setCryptoModal(false)} class="btn-close text-white" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                            <div className='col-12 col-md-6 text-center'>
                                <p className='text-center text-info'>Connect Wallet</p>


                                {address && isConnected ? (
                                    <>
                                        <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            {trimAddress(address)}
                                        </button>
                                        <button type="button" onClick={() => {
                                            open({ view: 'Networks' })
                                        }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            Change Network
                                        </button>
                                    </>

                                ) : (
                                    <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => open()}>
                                        Connect
                                    </button>
                                )
                                }

                            </div>
                            <div className='col-12 col-md-6'>

                                <form class="max-w-sm mx-auto">
                                    <div class="mt-2 mb-2">
                                        <label for="countries" class="block mb-2 text-md font-medium text-white">Buy With :</label>
                                        <select onChange={(e) => handleCurrencyChange(e.target.value, e.target.options[e.target.selectedIndex].text)} id="countries" class=" border border-gray-300 text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                                            <option selected>Select Currency</option>
                                            {currencyArray && currencyArray.length > 0 && currencyArray.map((items, key) => {
                                                return (
                                                    <option value={items.id} >{items.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <p className='text-warning crypto-sm-text'>Current Rate : ${formatPrice(rate, 5)}</p>
                                    <p className='text-warning crypto-sm-text'>Payble Amount : {formatPrice(payble, 5)} {currency.name ? currency.name : ' - '}</p>
                                    <button disabled={loading} type="button" onClick={() => handleBuy()} class="text-white mt-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        {loading ? 'Loading...' : 'Submit'}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={fiatModal}
                    onHide={() => setFiatModal(false)}
                >
                    <Modal.Header>
                        <Modal.Title>
                            Buy With Fiat
                        </Modal.Title>
                        <button type="button" onClick={() => setFiatModal(false)} class="btn-close text-white" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>
                        <p className='text-warning text-2xl text-center'>Payble Amount : ${formatPrice(cost, 5)}</p>
                        <PayPalButtons createOrder={createOrder} onApprove={onApprove} />
                    </Modal.Body>
                </Modal>
            </RelativeWrapper >
        </MainLayout >
    )
}
