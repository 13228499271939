import React, { useContext } from 'react';
import Container from '../components/layout/Container';
import CenteredBlock from '../components/layout/CenteredBlock';
import Heading from '../components/typography/Heading';
import Button from '../components/buttons/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useScrollToTopOnPageLoad from '../hooks/useScrollToTopOnPageLoad';
import rulesImg1 from '../assets/game/rules/1.png';
import rulesImg2 from '../assets/game/rules/2.png';
import rulesImg3 from '../assets/game/rules/3.png';
import rulesImg4 from '../assets/game/rules/4.png';
import rulesImg5 from '../assets/game/rules/5.png';
import MainLayout from '../layouts/_MainLayout';


const GameRules = () => {
  useScrollToTopOnPageLoad();

  return (
    <MainLayout>
      <Container fullHeight contentCenteredMobile padding="4rem 2rem 2rem 2rem" style={{ justifyContent: "center" }}>
        <CenteredBlock style={{ width: "80%" }}>
          <img src={rulesImg1} alt='rulesImg1' style={{ height: "auto" }} />
          <img src={rulesImg2} alt='rulesImg1' style={{ height: "auto", marginTop: "35px" }} />
          <img src={rulesImg3} alt='rulesImg1' style={{ height: "auto", marginTop: "35px" }} />
          <img src={rulesImg4} alt='rulesImg1' style={{ height: "auto", marginTop: "35px" }} />
          <img src={rulesImg5} alt='rulesImg1' style={{ height: "auto", marginTop: "35px" }} />
          <Wrapper style={{ textAlign: "center" }}>
            <Button as={Link} to="/" large primary fullWidthOnMobile autoFocus>
              Back
            </Button>
          </Wrapper>
        </CenteredBlock>
      </Container>
    </MainLayout>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: 1.5rem;
  }
`;

export default GameRules;
