import React, { useContext, useState } from 'react';
import { Modal } from 'react-bootstrap';
import globalContext from '../../context/global/globalContext';
import { toast } from 'react-toastify';
import { api } from '../../helpers/cookies';
import { usePricesStats } from '../../hooks/useState';
import { WITHDRAW_RATE, formatPrice } from '../../helpers/constant';


export default function WithdrawModal({ show, setShow }) {
    const [amount, setAmount] = useState(0);
    const [userAddress, setuserAddress] = useState('');
    const [bstAmount,setBstAmount] = useState(0);
    const [error, setError] = useState('');
    const { chipsAmount } = useContext(globalContext);
    

    const handleAmountChange = (e) => {
        let value = e.target.value;
        const patt = /^\d+\.{0,1}\d{0,6}$/;
        if (value === '' || value === 0) {
            setError('please enter valid amount.')
            setAmount(value);
            setBstAmount(parseFloat(parseFloat(value) * parseFloat(WITHDRAW_RATE)).toFixed(6))
        }
        else if (patt.test(value)) {
            setError('')
            setAmount(value);
            setBstAmount(parseFloat(parseFloat(value) * parseFloat(WITHDRAW_RATE)).toFixed(6))
        }
    }

    const handleSubmit = () => {
        if (parseFloat(chipsAmount) >= parseFloat(amount)) {
            api.post('/api/auth/addwithdraw', {
                bst_amount : bstAmount, 
                chips:amount,
                user_address : userAddress
            }, {
                headers: {
                    'x-auth-token': localStorage.token,
                },
            }).then((res) => {
                if (res && res.data && res.data.error === 'OK') {
                    toast.success('successfully submited withdraw request');
                    setShow(false)
                }
                else {
                    toast.error(res.data.error);
                }
            });
        }
        else {
            toast.error('Please enter valid, amount not have enough balance for withdraw')
        }
    }

    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => setShow(false)}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Withdraw Chips
                </Modal.Title>
                <button type="button" onClick={() => setShow(false)} class="btn-close" aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body>
                <p className='text-center'>Withdrawal Amount : {chipsAmount} </p>
                <form class="max-w-sm mx-auto">
                    <div class="mb-3">
                        <label for="amount" class="block mb-2 text-sm font-medium text-white">Enter Withdrawal Amount</label>
                        <input value={amount} onChange={(e) => handleAmountChange(e)} type="text"  class="text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="eg. 1000" required />
                        {error && <p className='text-danger'>{error}</p>}
                    </div>
                    <div class="mb-3">
                        <label for="amount" class="block mb-2 text-sm font-medium text-white">Enter Address</label>
                        <input value={userAddress} onChange={(e) => setuserAddress(e.target.value)} type="text"  class="text-sm rounded-lg  block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500" placeholder="eg. 0x0000000000000000" required />
                    </div>
                    <p className='text-info'>Recevie : {formatPrice(bstAmount , 5)} BST</p>

                    <button type="button" onClick={() => handleSubmit()} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}
