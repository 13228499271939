import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import authContext from '../../context/auth/authContext';

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isLoggedIn , isAdmin } = useContext(authContext);
    

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && isAdmin ?  <Component {...props} {...rest} /> : <Redirect to="/" />
      }
    />
  );
};

export default AdminRoute;
