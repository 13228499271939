import React, { useContext, useEffect, useState } from 'react';
import Container from '../components/layout/Container';
import Button from '../components/buttons/Button';
import gameContext from '../context/game/gameContext';
import socketContext from '../context/websocket/socketContext';
import PokerTable from '../components/game/PokerTable';
import { RotateDevicePrompt } from '../components/game/RotateDevicePrompt';
import { PositionedUISlot } from '../components/game/PositionedUISlot';
import { PokerTableWrapper } from '../components/game/PokerTableWrapper';
import { Seat } from '../components/game/Seat';
import Text from '../components/typography/Text';
import modalContext from '../context/modal/modalContext';
import { withRouter } from 'react-router-dom';
import { TableInfoWrapper } from '../components/game/TableInfoWrapper';
import { InfoPill } from '../components/game/InfoPill';
import { GameUI } from '../components/game/GameUI';
import { GameStateInfo } from '../components/game/GameStateInfo';
import PokerCard from '../components/game/PokerCard';
import ChatBox from '../components/chatbox/ChatBox';
import { useLocation } from 'react-router-dom';
import SoundButton from '../components/SoundButton';
import VoiceButton from '../components/VoiceButton';
import MainLayout from '../layouts/_MainLayout';
import globalContext from '../context/global/globalContext';


const Play = ({ history }) => {
  const { socket } = useContext(socketContext);
  const { id } = useContext(globalContext);
  const {
    messages,
    currentTable,
    isPlayerSeated,
    seatId,
    joinTable,
    leaveTable,
    sitDown,
    standUp,
    fold,
    check,
    call,
    raise,
    joinTournament
  } = useContext(gameContext);
  const location = useLocation();

  const [bet, setBet] = useState(0);

  useEffect(() => {
    !socket &&
      history.push('/')
  
    // if(id){
      // if (location.state && location.state.id && location.state.is_tournament) {
      //   socket && joinTournament(location.state.id , id );
      // }
      // else if(location.state && location.state.id){
      //   socket && joinTable(location.state.id);
      // }
      // else {
        socket && joinTable(1);
      // }
    // }

    return () => leaveTable();
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    currentTable &&
      (currentTable.callAmount > currentTable.minBet
        ? setBet(currentTable.callAmount)
        : currentTable.pot > 0
          ? setBet(currentTable.minRaise)
          : setBet(currentTable.minBet));
  }, [currentTable]);

  return (
    <>
      <MainLayout>
        <SoundButton />
        <VoiceButton />
        <ChatBox />
        <RotateDevicePrompt />
        <Container fullHeight>
          {currentTable && (
            <>

              <PositionedUISlot
                bottom="2vh"
                left="1.5rem"
                scale="0.65"
                style={{ zIndex: '50' }}
              >
                <Button small primary onClick={leaveTable}>
                  Leave Table
                </Button>
              </PositionedUISlot>
              {!isPlayerSeated && (
                <PositionedUISlot
                  bottom="1.5vh"
                  right="1.5rem"
                  scale="0.65"
                  style={{ pointerEvents: 'none', zIndex: '50' }}
                  origin="bottom right"
                >
                  <TableInfoWrapper>
                    <Text textAlign="right">
                      <strong>{currentTable.name}</strong> |{' '}
                      <strong>
                        Limit:{' '}
                      </strong>
                      {new Intl.NumberFormat(
                        document.documentElement.lang,
                      ).format(currentTable.limit)}{' '}
                      |{' '}
                      <strong>
                        Blinds:{' '}
                      </strong>
                      {new Intl.NumberFormat(
                        document.documentElement.lang,
                      ).format(currentTable.minBet)}{' '}
                      /{' '}
                      {new Intl.NumberFormat(
                        document.documentElement.lang,
                      ).format(currentTable.minBet * 2)}
                    </Text>
                  </TableInfoWrapper>
                </PositionedUISlot>
              )}
            </>
          )}
          <PokerTableWrapper>
            <PokerTable />
            {currentTable && (
              <>
                <PositionedUISlot
                  top="-2%"
                  left="0"
                  scale="0.55"
                  origin="top left"
                >
                  <Seat
                    seatNumber={1}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot top="-17%" scale="0.55" origin="top center">
                  <Seat
                    seatNumber={2}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  top="-2%"
                  right="0%"
                  scale="0.55"
                  origin="top right"
                >
                  <Seat
                    seatNumber={3}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  bottom="15%"
                  right="-5%"
                  scale="0.55"
                  origin="bottom right"
                >
                  <Seat
                    seatNumber={4}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  bottom="15%"
                  left="-5%"
                  scale="0.55"
                  origin="bottom left"
                >
                  <Seat
                    seatNumber={5}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  width="100%"
                  origin="center center"
                  scale="0.60"
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {currentTable.board && currentTable.board.length > 0 && (
                    <>
                      {currentTable.board.map((card, index) => (
                        <PokerCard key={index} card={card} />
                      ))}
                    </>
                  )}
                </PositionedUISlot>
                <PositionedUISlot bottom="8%" scale="0.60" origin="bottom center">
                  {messages && messages.length > 0 && (
                    <>
                      <InfoPill>{messages[messages.length - 1]}</InfoPill>
                      {!isPlayerSeated && (
                        <InfoPill>Sit down to join the game!</InfoPill>
                      )}
                      {currentTable.winMessages.length > 0 && (
                        <InfoPill>
                          {
                            currentTable.winMessages[
                            currentTable.winMessages.length - 1
                            ]
                          }
                        </InfoPill>
                      )}
                    </>
                  )}
                </PositionedUISlot>
                <PositionedUISlot
                  bottom="25%"
                  scale="0.60"
                  origin="center center"
                >
                  {currentTable.winMessages.length === 0 && (
                    <GameStateInfo currentTable={currentTable} />
                  )}
                </PositionedUISlot>
              </>
            )}
          </PokerTableWrapper>

          {currentTable &&
            isPlayerSeated &&
            currentTable.seats[seatId] &&
            currentTable.seats[seatId].turn && (
              <GameUI
                currentTable={currentTable}
                seatId={seatId}
                bet={bet}
                setBet={setBet}
                raise={raise}
                standUp={standUp}
                fold={fold}
                check={check}
                call={call}
              />
            )}
        </Container>
      </MainLayout>
    </>
  );
};

export default withRouter(Play);
