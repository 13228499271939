import React, { useContext } from 'react';
import LoadingScreen from './components/loading/LoadingScreen';
import globalContext from './context/global/globalContext';
import Routes from './components/routing/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const { isLoading } = useContext(
    globalContext,
  );


  return (
    <>
      <ToastContainer position="bottom-right" />
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Routes />
      )}
    </>
  );
};

export default App;
