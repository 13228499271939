import { useEffect, useState, useContext } from 'react';
import setAuthToken from '../helpers/setAuthToken';
import globalContext from '../context/global/globalContext';
import { api } from '../helpers/cookies';
import { toast } from 'react-toastify';

const useAuth = () => {
  localStorage.token && setAuthToken(localStorage.token);

  const {
    setId,
    setIsLoading,
    setUserName,
    setEmail,
    setChipsAmount
  } = useContext(globalContext);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const token = localStorage.token;
    token && loadUser(token);

    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  const register = async (name, email, password) => {
    setIsLoading(true);
    try {
      const res = await api.post('/api/users', {
        name,
        email,
        password,
      });

      if (res && res.data && res.data.error === 'OK') {

        const token = res.data.token;

        if (token) {
          localStorage.setItem('token', token);
          setAuthToken(token);
          await loadUser(token);
          toast.success('successfully register');
        }
      }
      else {
        toast.error(res.data.error)
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  const blockstarLogin = async (token) => {
    if (token) {
      localStorage.setItem('token', token);
      setAuthToken(token);
      await loadUser(token);
      toast.success('successfully login');
    }
  }

  const login = async (emailAddress, password) => {
    setIsLoading(true);
    try {
      const res = await api.post('/api/auth', {
        email: emailAddress,
        password,
      })

      if (res && res.data && res.data.error === 'OK') {
        const token = res.data.token;

        if (token) {
          localStorage.setItem('token', token);
          setAuthToken(token);
          await loadUser(token);
          toast.success('successfully login');
        }
      }
      else {
        toast.error(res.data.error);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  const loadUser = async (token) => {
    try {
      const res = await api.get('/api/auth', {
        headers: {
          'x-auth-token': token,
        },
      });

      const { _id, name, email, chipsAmount, type } = res.data;

      setIsLoggedIn(true);
      setId(_id);
      setUserName(name);
      setEmail(email);
      setChipsAmount(chipsAmount);
      setIsAdmin(type);
    } catch (error) {
      localStorage.removeItem(token);
      alert(error);
    }
  };

  const updateUser = async (token, email, newusername) => {
    const res = await api.post('/api/auth/update', { email: email, username: newusername }, {
      headers: {
        'x-auth-token': token,
      },
    });

    if (res && res.data && res.data.error === "OK") {
      toast.success('successfully updated');
    }
    else {
      toast.error(res.data.error)
    }


  }

  const logout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setId(null);
    setUserName(null);
    setEmail(null);
    setChipsAmount(null);
    setIsAdmin(null);
  };

  return [isLoggedIn, login, logout, register, loadUser, updateUser, isAdmin , blockstarLogin];
};

export default useAuth;
