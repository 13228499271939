import React, { useContext, useEffect, useState } from 'react'
import RelativeWrapper from '../components/layout/RelativeWrapper'
import '../styles/tables.css';
import modalContext from '../context/modal/modalContext';
import Button from '../components/buttons/Button';
import { ButtonGroup } from '../components/forms/ButtonGroup';
import { Form } from '../components/forms/Form';
import { FormGroup } from '../components/forms/FormGroup';
import { Input } from '../components/forms/Input';
import { api } from '../helpers/cookies';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';


export default function Admin() {
    const [tables, setTables] = useState({});
    const { openModal, closeModal } = useContext(modalContext);
    const [loading,setLoading] = useState(false);
    const [refersh,setRefersh] = useState(1);
    const history = useHistory()



    useEffect(() => {
        api.post('/api/auth/getusers', {}, {
            headers: {
                'x-auth-token': localStorage.token,
            },
        }).then((res) => {
            if (res && res.data && res.data.error === 'OK') {
                setTables(res.data.data)
            }
            else{
                toast.error(res.data.error);
                history.push('/');

            }
        });
    }, [refersh]);

    const handleOpenModal = (id) => {
        openModal(
            () => (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setLoading(true);
                        const amount = document.getElementById('amount').value;
                        api.post('/api/auth/updatebalance', { id , amount : amount}, {
                            headers: {
                                'x-auth-token': localStorage.token,
                            },
                        }).then((res) => {
                            if (res && res.data && res.data.error === 'OK') {
                                setLoading(false);
                                toast.success('updated');
                                setRefersh(Math.random());
                            }
                            else{
                                toast.error(res.data.error);
                                setLoading(false);
                            }
                        }).catch((err)=>{
                            console.log(err);
                            toast.error(err.message);
                            setLoading(false);
                        });

                        
                        
                        closeModal();
                    }}
                >
                    <FormGroup>
                        <label>Enter Amount</label>
                        <Input
                            id="amount"
                            type="number"
                        />
                    </FormGroup>
                    <ButtonGroup>
                        <Button primary type="submit" disabled={loading} fullWidth>
                            { loading ?  'loading...' :  'Submit'}
                        </Button>
                    </ButtonGroup>
                </Form>
            ),
            'Update Balance',
            'Cancel',
        );
        
    }
    


    return (
        // <RelativeWrapper>
            <div className='container livetables'>
                <h5>User List</h5>
                <table class="table table-dark table-bordered table-hover table-responsive">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Balance</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tables && Object.keys(tables).length > 0 && Object.values(tables).map((items, key) => {
                            return (
                                <tr key={key}>
                                    <th>{key + 1}</th>
                                    <td>{items.name ? items.name : ' - '}</td>
                                    <td>{items.chipsAmount}</td>
                                    <td>
                                        <button className='btn btn-primary' onClick={() => {
                                            handleOpenModal(items._id)
                                        }}>Update Balance</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        // </RelativeWrapper>
    )
}
