import React, { useContext, useEffect, useState } from 'react'
import RelativeWrapper from '../components/layout/RelativeWrapper'
import '../styles/tables.css';
import MainLayout from '../layouts/_MainLayout';
import { api } from '../helpers/cookies';
import { toast } from 'react-toastify';
import { formatPrice } from '../helpers/constant';
import WithdrawModal from '../components/modals/WithdrawModal';



export default function Withdrawals() {
    const [tables, setTables] = useState({});
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (localStorage.token) {
            api.post('/api/auth/getuserwithdraw', {}, {
                headers: {
                    'x-auth-token': localStorage.token,
                },
            }).then((res) => {

                if (res && res.data && res.data.error === 'OK') {
                    setTables(res.data.data)
                }
                else {
                    toast.error(res.data.error);
                }
            });
        }
    }, [localStorage]);


    return (
        <MainLayout>
            <RelativeWrapper>
                <div className='container livetables'>
                    <div className='d-flex justify-content-between mb-2'>
                        <h5>Your Withdrawals</h5>
                        <button type='button' onClick={()=>setShow(true)} class="text-white bg-blue-700 rounded-lg text-sm px-3 py-1 mb-3">
                            Withdraw
                        </button>
                    </div>
                    <table class="table table-dark table-bordered table-hover table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Chips</th>
                                <th scope="col">BST Amount</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tables && Object.keys(tables).length > 0 && Object.values(tables).map((items, key) => {
                                
                                return (
                                    <tr>
                                        <th>{key + 1}</th>
                                        <td>{items.chips ? formatPrice(items.chips, 5) : ' - '}</td>
                                        <td> {items.bst_amount ? formatPrice(items.bst_amount, 5) : ' - '}</td>
                                        <td>
                                            {items.status === 1 ? (
                                                <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                                    Success
                                                </span>
                                            ) : items.status === 2 ? (
                                                <span class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                                                    Pending
                                                </span>
                                            ) : (
                                                <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                                    Failed
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <WithdrawModal show={show} setShow={setShow} />
            </RelativeWrapper>
        </MainLayout>
    )
}
