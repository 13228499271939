import React, { useContext, useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
  CALL,
  CHECK,
  FOLD,
  JOIN_TABLE,
  JOIN_T_TABLE,
  LEAVE_TABLE,
  RAISE,
  REBUY,
  SIT_DOWN,
  STAND_UP,
  TABLE_JOINED,
  TABLE_LEFT,
  TABLE_UPDATED,
} from '../../pokergame/actions';
import authContext from '../auth/authContext';
import socketContext from '../websocket/socketContext';
import GameContext from './gameContext';
import audioTune from '../../assets/game/message.mp3';
import { toast } from 'react-toastify';
import AgoraRTC from "agora-rtc-sdk-ng"
import AgoraRTM from "agora-rtm-sdk"


const GameState = ({ history, children }) => {
  const { socket } = useContext(socketContext);
  const { loadUser } = useContext(authContext);
  const [messages, setMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [currentTable, setCurrentTable] = useState(null);
  const [isPlayerSeated, setIsPlayerSeated] = useState(false);
  const [seatId, setSeatId] = useState(null);
  const [turn, setTurn] = useState(false);
  const [turnTimeOutHandle, setHandle] = useState(null);
  const audio = new Audio(audioTune);
  const [isMute, setIsMute] = useState(true);
  const [messageSound, setMessageSound] = useState(true);
  const [isMicOn, setIsMicOn] = useState(false);

  const currentTableRef = React.useRef(currentTable);

  const token = null
  const rtcUid = Math.floor(Math.random() * 2032)
  const rtmUid = String(Math.floor(Math.random() * 2032))

  let audioTracks = useRef({
    localAudioTrack: null,
    remoteAudioTracks: {},
  });

  const rtcClient = useRef(null);
  const rtmClient = useRef(null);
  const channel = useRef(null);
  const appid = process.env.REACT_APP_AGORA_APPID;



  useEffect(() => {
    currentTableRef.current = currentTable;
    
    isPlayerSeated &&
      seatId &&
      currentTable.seats[seatId] &&
      turn !== currentTable.seats[seatId].turn &&
      setTurn(currentTable.seats[seatId].turn);
    // eslint-disable-next-line
  }, [currentTable]);

  useEffect(() => {

    if (turn && !turnTimeOutHandle) {
      const handle = setTimeout(()=>{
        fold(true)
      }, 15000);
      setHandle(handle);
      if (!isMute) audio.play();

    } else {
      turnTimeOutHandle && clearTimeout(turnTimeOutHandle);
      turnTimeOutHandle && setHandle(null);
    }
    // eslint-disable-next-line
  }, [turn]);

  useEffect(() => {
    if (socket) {
      window.addEventListener('unload', leaveTable);
      window.addEventListener('close', leaveTable);

      socket.on(TABLE_UPDATED, ({ table, message, from }) => {
        console.log(TABLE_UPDATED, table, message, from);
        setCurrentTable(table);
        message && addMessage(message);
      });

      socket.on(TABLE_JOINED, ({ tables, tableId }) => {
        console.log(TABLE_JOINED, tables, tableId);
        setCurrentTable(tables[tableId]);
      });

      socket.on(TABLE_LEFT, ({ tables, tableId }) => {
        console.log(TABLE_LEFT, tables, tableId);
        setCurrentTable(null);
        loadUser(localStorage.token);
        setMessages([]);
      });

      socket.on('GETCHATMESSAGE', (response) => {
        if (response && response.message) {
          let parseMessage = JSON.parse(response.message);

          setNewMessages(parseMessage);
        }
      })

      socket.on('created', function (room) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {

          enterRoom()
        }).catch(function (err) {
          console.log(err.message);
          toast.error('An error ocurred when accessing media devices');
        });
      });

      socket.on('joined', function (room) {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
          enterRoom()
        }).catch(function (err) {
          toast.error('An error ocurred when accessing media devices');
        });
      });

      socket.on('JOIN_T_FAILED' , function(message){
        toast.error(message);
      })
    }
    return () => leaveTable();
    // eslint-disable-next-line
  }, [socket]);

  const joinTable = (tableId) => {
    console.log(JOIN_TABLE, tableId);
    socket.emit(JOIN_TABLE, tableId);
    socket.emit('CHATGET', { tableId });

  };

  const updateMessages = () => {
    socket.emit('CHATGET', { tableId: currentTableRef.current.id });
  }

  const sentMessage = (message) => {
    socket.emit('CHATMESSAGE', { tableId: currentTableRef.current.id, message });
  }

  const leaveTable = () => {
    try {
      isPlayerSeated && standUp();
      if (audioTracks.current) {
        audioTracks.current.localAudioTrack.stop()
        audioTracks.current.localAudioTrack.close()
      }

      if (rtcClient.current) {
        rtcClient.current.unpublish()
        rtcClient.current.leave()
        leaveRtmChannel();
      }

      if (currentTableRef &&
        currentTableRef.current &&
        currentTableRef.current.id) {

        socket.emit(LEAVE_TABLE, currentTableRef.current.id);
        window.location.href = process.env.REACT_APP_PUBLIC_URI

      }
    }
    catch (err) {
      if (currentTableRef &&
        currentTableRef.current &&
        currentTableRef.current.id) {
        window.location.href = process.env.REACT_APP_PUBLIC_URI
      }
    }

  };

  let leaveRtmChannel = async () => {
    try {
      await channel.current.leave()
      await rtmClient.current.logout()
    } catch (err) {
      return false;
    }
  }

  const sitDown = (tableId, seatId, amount) => {
    socket.emit('create or join', tableId);
    socket.emit(SIT_DOWN, { tableId, seatId, amount });
    setIsPlayerSeated(true);
    setSeatId(seatId);
  };

  const rebuy = (tableId, seatId, amount) => {
    console.log(tableId);
    console.log(seatId);
    console.log(amount);
    socket.emit(REBUY, { tableId, seatId, amount });
  };

  const standUp = () => {
    currentTableRef &&
      currentTableRef.current &&
      socket.emit(STAND_UP, currentTableRef.current.id);
    setIsPlayerSeated(false);
    setSeatId(null);

  };

  const addMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
    console.log(message);
  };

  const fold = (istimeout = false) => {
    currentTableRef &&
      currentTableRef.current &&
      socket.emit(FOLD, currentTableRef.current.id,istimeout);
  };

  const check = () => {
    currentTableRef &&
      currentTableRef.current &&
      socket.emit(CHECK, currentTableRef.current.id);
  };

  const call = () => {
    currentTableRef &&
      currentTableRef.current &&
      socket.emit(CALL, currentTableRef.current.id);
  };

  const raise = (amount) => {
    currentTableRef &&
      currentTableRef.current &&
      socket.emit(RAISE, { tableId: currentTableRef.current.id, amount });
  };

  const setMute = () => {
    setIsMicOn(false);

    try {
      audioTracks.current.localAudioTrack.setMuted(true)
    }
    catch (err) {
      console.log(err.message);
    }
  }

  const setUnMute = () => {
    setIsMicOn(true);
    try {
      audioTracks.current.localAudioTrack.setMuted(false)
    }
    catch (err) {
      console.log(err.message);
    }
  }

  const initRtm = async (roomId) => {

    rtmClient.current = AgoraRTM.createInstance(appid)
    await rtmClient.current.login({ 'uid': rtmUid, 'token': token })


    channel.current = rtmClient.current.createChannel(roomId)
    await channel.current.join()

    window.addEventListener('beforeunload', leaveRtmChannel)
    channel.current.on('MemberLeft', handleMemberLeft)
  }




  let handleMemberLeft = async (MemberId) => {
    document.getElementById(MemberId).remove()
  }


  const initRtc = async (roomId) => {
    rtcClient.current = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    rtcClient.current.on("user-published", handleUserPublished)
    rtcClient.current.on("user-left", handleUserLeft);


    await rtcClient.current.join(appid, roomId, token, rtcUid)
    audioTracks.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    audioTracks.current.localAudioTrack.setMuted(!isMicOn)
    await rtcClient.current.publish(audioTracks.current.localAudioTrack);

  }

  const enterRoom = async () => {
    initRtc(`table_channel_${currentTableRef.current.id}`)
    initRtm(`table_channel_${currentTableRef.current.id}`)
  }


  let handleUserPublished = async (user, mediaType) => {
    await rtcClient.current.subscribe(user, mediaType);

    if (mediaType === "audio") {
      audioTracks.current.remoteAudioTracks[user.uid] = [user.audioTrack]
      user.audioTrack.play();
    }
  }

  let handleUserLeft = async (user) => {
    delete audioTracks.current.remoteAudioTracks[user.uid]
    //document.getElementById(user.uid).remove()
  }

  let joinTournament = (t_id,user_id) => {
    socket.emit(JOIN_T_TABLE, t_id , user_id);
  }


  return (
    <GameContext.Provider
      value={{
        messages,
        currentTable,
        isPlayerSeated,
        seatId,
        joinTable,
        leaveTable,
        sitDown,
        standUp,
        addMessage,
        fold,
        check,
        call,
        raise,
        rebuy,
        chatMessages,
        newMessages,
        setNewMessages,
        sentMessage,
        updateMessages,
        setChatMessages,
        isMute,
        setIsMute,
        isMicOn,
        setIsMicOn,
        setMute,
        setUnMute,
        messageSound,
        setMessageSound,
        joinTournament
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export default withRouter(GameState);
