import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../../pages/HomePage';
import RegistrationPage from '../../pages/RegistrationPage';
import LoginPage from '../../pages/LoginPage';
import Dashboard from '../../pages/Dashboard';
import Play from '../../pages/Play';
import ProtectedRoute from './ProtectedRoute';
import NotFoundPage from '../../pages/NotFoundPage';
import GameRules from '../../pages/GameRules';
import Tables from '../../pages/Tables';
import Admin from '../../pages/Admin';
import AdminRoute from './AdminRoute';
import Pricing from '../../pages/Pricing';
import Withdrawals from '../../pages/Withdrawals';
import Tournament from '../../pages/Tournament';
import Test from '../../pages/Test';

const Routes = () => {


  return (
    <Switch>

      <Route path="/" exact component={HomePage} />
      <Route path="/register" component={RegistrationPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/tables" component={Tables} />

      <Route path="/game-rules" component={GameRules} />
      <Route path="/test" component={Test} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/play" component={Play} />
      <ProtectedRoute path="/pricing" component={Pricing} />
      <ProtectedRoute path="/withdrawals" component={Withdrawals} />

      {/* <ProtectedRoute path="/tournament" component={Tournament} /> */}

      <AdminRoute path="/admin" component={Admin} />
      <Route component={NotFoundPage} />

    </Switch>
  );
};

export default Routes;
