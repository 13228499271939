import React from 'react';

import styled from 'styled-components';

const StyledTiledBackgroundImage = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
  }
`;

const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #000000;
  }
`;

export const TiledBackgroundImage = () => (
  <Wrapper>
    <StyledTiledBackgroundImage />
  </Wrapper>
);
