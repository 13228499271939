import React, { useContext, useEffect, useState } from 'react'
import RelativeWrapper from '../components/layout/RelativeWrapper'
import '../styles/tables.css';
import socketContext from '../context/websocket/socketContext';
import { Link } from 'react-router-dom';
import MainLayout from '../layouts/_MainLayout';



export default function Tables() {
    const { socket } = useContext(socketContext);
    const [tables, setTables] = useState({});

    useEffect(() => {
        if (socket) {

            socket.emit('alltables');

            socket.on('getalltables', (response) => {
                if (response && response.tables) {
                    let parseMessage = JSON.parse(response.tables);
                    setTables(parseMessage)
                }

            })
        }
    }, [socket]);


    return (
        <MainLayout>
            <RelativeWrapper>
                <div className='container livetables'>
                    <h5>Live Tables</h5>
                    <table class="table table-dark table-bordered table-hover table-responsive">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Players</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tables && Object.keys(tables).length > 0 && Object.values(tables).map((items, key) => {
                                console.log(items);
                                let filledSeats = items.seats && Object.values(items.seats).filter((rowdata) => rowdata !== null);
                                return (
                                    <tr>
                                        <th>{key + 1}</th>
                                        <td>{items.name ? items.name : ' - '}</td>
                                        <td> {filledSeats.length}  / {items.maxPlayers ? items.maxPlayers : 0}</td>
                                        <td>
                                            <Link className="btn btn-primary" to={{
                                                pathname: '/play',
                                                state: { id: items.id }
                                            }} >Join</Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </RelativeWrapper>
        </MainLayout>
    )
}
