import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '../components/layout/Container';
import HeadingWithLogo from '../components/typography/HeadingWithLogo';
import Button from '../components/buttons/Button';
import { FormGroup } from '../components/forms/FormGroup';
import { Label } from '../components/forms/Label';
import { Input } from '../components/forms/Input';
import styled from 'styled-components';
import { Form } from '../components/forms/Form';
import RelativeWrapper from '../components/layout/RelativeWrapper';
import globalContext from '../context/global/globalContext';
import authContext from '../context/auth/authContext';
import MainLayout from '../layouts/_MainLayout';


const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;

  ${FormGroup} > *~* {
    margin: 0.5rem 0;
  }

  @media screen and (max-width: 624px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 320px;

    ${FormGroup} > *~* {
      margin: 0.5rem 0;
    }
  }
`;

// const DangerButton = styled(Button)`
//   background-color: ${(props) => props.theme.colors.dangerColorLighter};
//   color: ${(props) => props.theme.colors.fontColorLight};

//   &:visited,
//   &:hover,
//   &:active,
//   &:focus {
//     background-color: ${(props) => props.theme.colors.dangerColor};
//     color: ${(props) => props.theme.colors.fontColorLight};
//   }
// `;

const Dashboard = () => {
  const { userName, email } = useContext(globalContext);
  const { updateUser } = useContext(authContext);
  const [newusername, setNewusername] = useState('')

  useEffect(() => {
    setNewusername(userName)
  }, [userName])


  return (
    <MainLayout>
      <RelativeWrapper>
        {/* <TiledBackgroundImage /> */}
        <Container
          fullHeight
          flexDirection="column"
          justifyContent="center"
          contentCenteredMobile
          alignItems="center"
          padding="6rem 2rem 2rem 2rem"
        >
          <Form onSubmit={(e) => e.preventDefault()}>
            <HeadingWithLogo textCentered hideIconOnMobile={false}>
              Dashboard
            </HeadingWithLogo>
            <Wrapper>
              <FormGroup>
                <Label>Username</Label>
                <Input value={newusername} onChange={(e) => setNewusername(e.target.value)} />
                {/* <Button primary>
                {getLocalizedString('dashboard-nickname_btn_txt')}
              </Button> */}
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input type="email" value={email} />
                {/* <Button primary>
                {getLocalizedString('dashboard-email_btn_txt')}
              </Button> */}
              </FormGroup>
              <FormGroup style={{ gridColumnStart: '1', gridColumnEnd: '3' }}>
                <Button primary onClick={() => updateUser(localStorage.token, email, newusername)}>
                  Submit
                </Button>
              </FormGroup>
              <Button
                as={Link}
                to="/"
                secondary
                style={{ gridColumnStart: '1', gridColumnEnd: '3' }}
              >
                Back
              </Button>
            </Wrapper>
          </Form>
        </Container>
      </RelativeWrapper>
    </MainLayout>
  );
};

export default Dashboard;
