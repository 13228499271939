import React, { useContext, useEffect, useState } from 'react'
import gameContext from '../context/game/gameContext';
import socketContext from '../context/websocket/socketContext';
import { TABLES_UPDATED, TABLE_JOINED } from '../pokergame/actions';

export default function Test() {
    const { joinTournament} = useContext(gameContext);
    const { socket } = useContext(socketContext);
    let t_id = "65a2637d5e3b983b4ab4e41f";
    let u_id = "6597bf2704021b842d2e1fa0";
    const [currentTable, setCurrentTable] = useState(null);
    
    const handleJoinT = () =>{
        joinTournament(t_id,u_id);
    }

    useEffect(() => {
      if (socket) {
        // window.addEventListener('unload', leaveTable);
        // window.addEventListener('close', leaveTable);
  
        socket.on(TABLES_UPDATED, ({ table, message, from }) => {
          console.log(TABLES_UPDATED, table, message, from);
          setCurrentTable(table);
         
        });
  
        socket.on(TABLE_JOINED, ({ tables, tableId }) => {
          console.log(TABLE_JOINED, tables, tableId);
          setCurrentTable(tables[tableId]);
        });
  
      }
     
      // eslint-disable-next-line
    }, [socket]);



    useEffect(()=>{
      console.log(currentTable);
    },[currentTable]);
    

  return (
    <div>
        <button onClick={()=>handleJoinT()} className='text-white bg-blue-700 rounded-lg text-sm px-3 py-3'>Join Tournament</button>
    </div>
  )
}
