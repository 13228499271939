import React, { useContext } from 'react';
import Container from '../components/layout/Container';
import CenteredBlock from '../components/layout/CenteredBlock';
import Heading from '../components/typography/Heading';
import Button from '../components/buttons/Button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useScrollToTopOnPageLoad from '../hooks/useScrollToTopOnPageLoad';
import MainLayout from '../layouts/_MainLayout';


const NotFoundPage = () => {
  useScrollToTopOnPageLoad();

  return (
    <MainLayout>
      <Container fullHeight contentCenteredMobile padding="4rem 2rem 2rem 2rem">
        <CenteredBlock>
          <Heading as="h2" headingClass="h1" textCenteredOnMobile>
            Coming Soon
          </Heading>
          <Wrapper>
            <Button as={Link} to="/" large primary fullWidthOnMobile autoFocus>
              Back
            </Button>
          </Wrapper>
        </CenteredBlock>
      </Container>
    </MainLayout>
  );
};

const Wrapper = styled.div`
  @media screen and (min-width: 1024px) {
    margin-top: 1.5rem;
  }
`;

export default NotFoundPage;
